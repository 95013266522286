// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sQ d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sR d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sS d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sT d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sV d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sW d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sX d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rS q_rS";
export var heroExceptionNormal = "s_rT q_rT";
export var heroExceptionLarge = "s_rV q_rV";
export var Title1Small = "s_rt q_rt q_qZ q_q0";
export var Title1Normal = "s_rv q_rv q_qZ q_q1";
export var Title1Large = "s_rw q_rw q_qZ q_q2";
export var BodySmall = "s_rJ q_rJ q_qZ q_rh";
export var BodyNormal = "s_rK q_rK q_qZ q_rj";
export var BodyLarge = "s_rL q_rL q_qZ q_rk";