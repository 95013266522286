// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_sY d_gS d_cw d_dv";
export var quoteParagraphCenter = "t_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "t_gV d_gV d_cw d_dx";
export var quoteRowLeft = "t_sZ d_bG";
export var quoteRowCenter = "t_s0 d_bD";
export var quoteRowRight = "t_s1 d_bH";
export var quoteWrapper = "t_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "t_gR d_gR";
export var quoteExceptionSmall = "t_rZ q_rZ";
export var quoteExceptionNormal = "t_r0 q_r0";
export var quoteExceptionLarge = "t_r1 q_r1";
export var quoteAuthorExceptionSmall = "t_r2 q_r2";
export var quoteAuthorExceptionNormal = "t_r3 q_r3";
export var quoteAuthorExceptionLarge = "t_r4 q_r4";